import React, { useCallback, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import {
  CloseButton,
  CrossIcon,
  ModalStyled,
  OverlayContainer,
  OverlayContent,
  OverlayContentWrapper,
  OverlayTitle,
  OverlayTitleWrapper,
} from './SlidingOverlay.styled';

interface SlidingOverlayProps {
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  isEnter?: boolean;
  onEnter?: () => void;
  onContentEnter?: () => void;
  title?: string;
  contentWidth?: string;
  onEnterTimeout?: number;
  titleWithBorder?: boolean;
}

function SlidingOverlay({
  isOpen,
  onClose,
  children,
  title,
  contentWidth = '60rem',
  onEnter,
  onContentEnter,
  isEnter,
  onEnterTimeout = 500,
  titleWithBorder = false,
}: SlidingOverlayProps) {
  const contentRef = useRef(null);
  const [isModalEnter, setIsModalEnter] = useState(false);

  const onCloseCallback = useCallback(() => {
    onClose();
    setIsModalEnter(false);
  }, [onClose]);

  return (
    <ModalStyled
      isOpen={isOpen}
      fullScreen
      exit
      mountOnEnter
      unmountOnExit
      timeout={{ exit: 500 }}
      withCloseButton={false}
      onClose={onCloseCallback}
      onEnter={() => {
        setIsModalEnter(true);
        onEnter?.();

        if (onContentEnter) {
          setTimeout(() => {
            onContentEnter();
          }, onEnterTimeout);
        }
      }}
      shouldRenderFocusTrap
    >
      <OverlayContainer>
        <Transition
          in={isEnter ?? isModalEnter}
          mountOnEnter
          nodeRef={contentRef}
          timeout={0}
        >
          {transitionState => (
            <OverlayContentWrapper $transitionState={transitionState}>
              <OverlayContent $contentWidth={contentWidth}>
                <OverlayTitleWrapper $titleWithBorder={titleWithBorder}>
                  <OverlayTitle>{title}</OverlayTitle>

                  <CloseButton type="button" onClick={onCloseCallback}>
                    <CrossIcon width={17} height={17} />
                  </CloseButton>
                </OverlayTitleWrapper>

                {children}
              </OverlayContent>
            </OverlayContentWrapper>
          )}
        </Transition>
      </OverlayContainer>
    </ModalStyled>
  );
}

export default SlidingOverlay;
